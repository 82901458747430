import React from "react"
import {
    Box,
    CircularProgress,
    FormControl,
    Input as InlineInput,
    InputAdornment,
    OutlinedInput,
} from "@mui/material"
import PredictionsList from "../PredictionsList"
import { useClickOutsideDetector } from "../../hooks/useClickOutsideDetector"
import { ReactComponent as SearchIconFill } from "../../assets/icons/search-fill.svg"
import { getMockSuggestions } from "../../utils/data"
import { fetchPredictions } from "../../utils/API/guides"

const SearchInput = ({
    placeHolder = "Busca un destino o tour",
    styles = {},
    source = "",
    SearchIcon = SearchIconFill,
    variant = "outlined"
}) => {
    const [value, setValue] = React.useState("")
    const [results, setResults] = React.useState({})
    const [openModal, setOpenModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const wrapperRef = React.useRef(null);

    const inputVariants = {
        inline: InlineInput,
        outlined: OutlinedInput
    }
    const Input = inputVariants[variant];

    const getGuidesPredictions = async (value) => {
        setIsLoading(true)
        const baseURL = process.env.REACT_APP_BASE_URL
        const predictions = await fetchPredictions(baseURL, value)
        const mapPredictions = predictions.map(item => ({
            name: item.title,
            path: `/guias-de-viaje/${item.slug}?id=${item.id}`,
        }))
        setIsLoading(false)
        return {
            'Guías de viaje': mapPredictions,
        }
    }

    useClickOutsideDetector(wrapperRef, () => {
        setOpenModal(false);
    })

    const handleOnChange = async (evt) => {
        const { value } = evt.target;
        setValue(value)
        if (!value) {
            setOpenModal(false);
        } else {
            setOpenModal(true)
            const suggestions = source === "guides" ?
                await getGuidesPredictions(value) :
                getMockSuggestions(value)
            setResults(suggestions)
        }
    }

    const Loading = () => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: 3,
        }}>
            <CircularProgress />
        </Box>
    )

    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: 650,
                width: "100%",
                ...styles
            }}>
            <FormControl ref={wrapperRef} sx={{ m: 1, width: "100%", position: "relative"}}>
                <Input
                    color="primary"
                    placeholder={placeHolder}
                    type={"text"}
                    sx={{
                        borderRadius: variant === "outlined" ? 30 : 0,
                        paddingLeft: 1,
                        paddingRight: variant === "outlined" ? 2 : 1,
                        backgroundColor: variant === "outlined" ? "#ffffff" : 'transparent',
                        input: {
                            "&::placeholder": {
                                opacity: 0.8,
                                color: variant === "outlined" ? "#666B6E" : "#40C5E8"
                            },
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon style={{
                                width: variant === "outlined" ? 36 : 30,
                                height: variant === "outlined" ? 36 : 30
                            }} />
                        </InputAdornment>
                    }
                    onChange={handleOnChange}
                />
                {openModal ? (
                    <Box sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: 3,
                        boxShadow: 10,
                        float: "bottom",
                        maxHeight: "20rem",
                        mt: variant === "outlined" ? "4rem" : "2.5rem",
                        position: "absolute",
                        overflowY: "scroll",
                        width: "100%",
                        zIndex: 10
                    }}>
                        {isLoading ? <Loading /> : <PredictionsList data={results} value={value} />}
                    </Box>
                ) : null}
            </FormControl>
        </Box>
    )
}

export default SearchInput
