import React from 'react'
import {
    Container,
    Typography
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
// components
import Breadcrumbs from '../../components/Breadcrumbs'
import BlogArticleButtons from '../../components/BlogArticleButtons'
import BlurImageWrapper from '../../components/BlurImageWrapper'
import DestinationGallery from '../../components/DestinationGallery'
import MainLayout from '../../components/MainLayout'
import RichText from '../../components/RichText'
// hooks
import useFetchGuideById from '../../hooks/API/Guides/useFetchGuideById'
import useFetchTopGuides from '../../hooks/API/Guides/useFetchTopGuides'
// icons
import { ReactComponent as InfoIcon } from '../../assets/icons/info-white.svg'
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg'
import { ReactComponent as BagpackIcon } from '../../assets/icons/bagpack.svg'
import { ReactComponent as TicketIcon } from '../../assets/icons/ticket.svg'
import { ReactComponent as CampTentIcon } from '../../assets/icons/camp-tent.svg'
import { ReactComponent as PlateIcon } from '../../assets/icons/plate.svg'
import GuiasBlogSkeleton from './GuiasBlogSkeleton'
import './styles.css'

const GuiasBlog = () => {
    const [page, city] = window.location.pathname.split('/').filter(Boolean)
    const [sections, setSections] = React.useState([])
    
    const [params] = useSearchParams()
    const [heroTitle, setHeroTitle] = React.useState('');
    const guideId = params.get('id')
    const pageSection = params.get('section')
    const { topGuides } = useFetchTopGuides()
    const { guide: pageData, isLoading: isPageLoading } = useFetchGuideById(guideId, pageSection)

    const breadcrumbs = [
        { 
            name: 'Guías de viaje',
            path: `/${page}` 
        },
        { 
            name: pageData?.city,
            path: `/${page}/${city}`
        },
    ]

    React.useEffect(() => {
        const sectionsData = pageData?.sections || []
        if (sectionsData.length) {
            const placeName = pageData?.city
            const defaultSections = [
                {
                    title: 'Información principal',
                    name: 'informacion-principal-de',
                    Icon: InfoIcon
                },
                {
                    title: `Qué ver en ${placeName}`,
                    name: 'que-ver-en',
                    Icon: CameraIcon
                },
                {
                    title: `Excursiones en ${placeName}`,
                    name: 'excursiones-de',
                    Icon: BagpackIcon
                },
                {
                    title: `Qué hacer en ${placeName}`,
                    name: 'que-hacer-en',
                    Icon: TicketIcon
                },
                {
                    title: `Dónde dormir en ${placeName}`,
                    name: 'donde-dormir-en',
                    Icon: CampTentIcon
                },
                {
                    title: `Gastronomía en ${placeName}`,
                    name: 'gastronomia-de',
                    Icon: PlateIcon
                }
            ]
        
            setHeroTitle(`${placeName} - ${sectionsData[0]?.section_name}`);
            document.title = sectionsData[0]?.title;

            const btnSections = defaultSections.map(section => {
                const path = `/guias-de-viaje/${placeName}/${section.name}-${placeName.toLowerCase()}?id=${guideId}&section=${section.name}`
                return ({
                    ...section,
                    path,
                })
            })
            setSections(btnSections)
        }
    }, [city, guideId, pageData])


    return (
        <MainLayout>
            {
                isPageLoading ? (
                    <GuiasBlogSkeleton />
                ) : (
                    <>
                        <BlurImageWrapper imageUrl={pageData?.imagen} >
                            <Container sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                py: '4rem'
                            }}>
                                <Typography
                                    color='primary'
                                    sx={{
                                        fontWeight: 500,
                                        lineHeight: 0,
                                    }}
                                        variant='textBody'
                                        component="h2"
                                >
                                    Guía de viaje
                                </Typography>
                                <Typography
                                        variant='h3'
                                        component="h1"
                                    color='secondary.contrastText'
                                    sx={{
                                        fontWeight: 'bold',
                                        mb: '1rem'
                                    }}
                                >
                                    {heroTitle}
                                </Typography>
                                    <Typography
                                        color='secondary.contrastText'
                                        variant='subtitle1'
                                        component='h2'
                                    >
                                    {pageData?.title}
                                </Typography>
                            </Container>
                        </BlurImageWrapper>
                        <Container>
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                            <BlogArticleButtons
                                placeName={pageData?.city}
                                activeSection={pageSection || 'main-information'}
                                sections={sections}
                            />
                            <div className='guides-content-container'>    
                                <RichText text={pageData?.sections?.[0]?.htmlcontent || ''}/>
                            </div>
                        </Container>
                        <DestinationGallery title='Otros han buscado' destinations={topGuides || []} textPosition='center'/>
                    </>
                )
            }
        </MainLayout>
    )
}

export default GuiasBlog
