import React from 'react'
import axios from 'axios'


const useGetToken = () => {
    const setToken = React.useCallback(async () => {
        const { REACT_APP_BASE_URL } = process.env
        const headers = {
            username: 'api',
            password: 'pass',
        }
        const { data } = await axios.get(
            `${REACT_APP_BASE_URL}/index.php?r=api/token`,
            { headers }
        ) || {}

        if (data?.token) {
            localStorage.setItem('token', data.token)
        }
    }, [])

    const validateToken = React.useCallback(async (storedToken) => {
        const { REACT_APP_BASE_URL } = process.env
        try {
            await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api/auth/validate`,
                {
                    headers: {
                        'x-authorization-token': storedToken
                    }
                }
            )
        } catch (error) {
            if (error.status === 406) {
                setToken()
            }
        }
    }, [setToken])

    React.useEffect(() => {
        const storedToken = localStorage.getItem('token')

        if (!storedToken) {
            try {
                setToken()
            } catch (error) {
                console.error('error fetching token ', error)
            }
        } else {
            try {
                validateToken(storedToken)
            } catch (error) {
                console.error('Error validating token ', error)
            }
        }
    }, [setToken, validateToken])
}

export default useGetToken
